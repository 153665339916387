<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    :icon="icon"
    :icon-height="32"
    :icon-width="32"
    :loading="loading"
    ok-only
    submit-title="Got It"
    :title="title"
    width="480"
    v-on="$listeners"
    @submit="confirm"
  >
    <template #mainSlot>
      <div class="success-modal-message-wrapper break-word">
        {{ message }}
      </div>
    </template>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    paymentStatus: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      if (this.paymentStatus == 'success') {
        return 'Your payment is succesfull'
      }
      if (this.paymentStatus == 'failed') {
        return 'Payment Failure'
      }
      return ''
    },
    message() {
      if (this.paymentStatus == 'success') {
        return 'Thank you for your payment. An automated receipt will be sent to your registered email.'
      }
      if (this.paymentStatus == 'failed') {
        return 'It seems we have not received money. Please go back and try again.'
      }
      return ''
    },
    icon() {
      return this.paymentStatus
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style>
.success-modal-message-wrapper {
  padding: 24px 13px 24px 24px;
  font-weight: 500;
  line-height: 150%;
}
</style>
