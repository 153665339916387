<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    closable
    full-buttons
    :loading="loading"
    :max-height-form="windowHeight < 650 ? '40vh' : '70dvh'"
    :overflowing-form="windowHeight < 650"
    submit-title="Continue"
    :title="`Buy ${packageData.name}`"
    width="480"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="confirm"
  >
    <template #mainSlot>
      <div class="flex column ma-4 m-ma-3">
        <div class="flex-1 mb-4">
          <GlBoxWrap class="buy-modal-content-wrapper">
            <CartBuyContent
              :cart-data="packageData"
              show-price
            />
          </GlBoxWrap>
        </div>
        <div class="flex-1">
          <GlSelect
            v-model="paymentMethod"
            :clearable="false"
            label="Payment method"
            label-select="label"
            light-gray
            :options="paymentMethodsItems"
            @open="scrollToAim('.vs__dropdown-menu', 'end')"
          />
        </div>
      </div>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import CartBuyContent from '@/pages/payment/components/CartBuyContent'
import GlBoxWrap from '@/components/layout/gl-box-wrap.vue'
import GlSelect from '@/components/gl-select'
//utils
import { scrollToViewSelector } from '@/utils/scroll-to'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlModal,
    CartBuyContent,
    GlBoxWrap,
    GlSelect,
  },
  mixins: [deviceWidthMixin],
  inheritAttrs: false,
  props: {
    packageData: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      paymentMethod: {
        key: 'card',
        label: 'Card',
        path: 'card',
      },
      paymentMethods: [
        {
          key: 'card',
          label: 'Card',
          path: 'card',
        },
        {
          key: 'crypto',
          label: 'Crypto',
          path: 'crypto',
        },
      ],
    }
  },
  computed: {
    paymentMethodsItems() {
      if (this.packageData.price < 5) {
        return this.paymentMethods.filter(obj => obj.key !== 'crypto')
      }
      return this.paymentMethods
    },
  },
  methods: {
    scrollToViewSelector,
    confirm() {
      this.$emit('confirm', {
        packageData: this.packageData,
        paymentMethod: this.paymentMethod,
      })
    },
    scrollToAim(wrap, aim) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.scrollToViewSelector(wrap, aim)
        })
      }, 300)
    },
  },
}
</script>

<style>
.buy-modal-content-wrapper {
  margin: 0;
}
.buy-modal-content-wrapper .cart-buy-content-wrapper {
  margin: 0;
}
</style>
