<template>
  <div class="payment-history">
    <div class="payment-history-table-wrapper">
      <div class="payment-history-head-wrapper">
        <div> Payment History </div>

        <div
          class="pointer t-display-none"
          @click="$emit('toggle-history')"
        >
          <transition-group name="fade-leave">
            <div
              v-if="isPaymentHistoryOpen"
              key="panel-close"
            >
              <gl-icon
                :height="20"
                name="panel-close"
                :width="20"
              />
            </div>
            <div
              v-else
              key="panel-open"
            >
              <gl-icon
                :height="24"
                name="panel-open"
                :width="24"
              />
            </div>
          </transition-group>
        </div>
      </div>
      <DataTable
        class="flex-1 p-table-groupped p-table-nowrap-data"
        :lazy="true"
        :loading="loading"
        :paginator="Boolean(historyData.length)"
        responsive-layout="scroll"
        :rows="perPage"
        :rows-per-page-options="pagesOptions"
        scroll-height="flex"
        :scrollable="true"
        :total-records="totalRecords"
        :value="historyData"
        @page="onPage($event)"
        @sort="onSort($event)"
      >
        <Column
          field="packageName"
          header="Package Name"
          :sortable="isPaymentHistoryOpen"
        >
          <template #body="slotProps">
            <div class="bold-700">
              {{ slotProps.data.packageName }}
            </div>
          </template>
        </Column>
        <Column
          field="price"
          header="Price"
          :sortable="isPaymentHistoryOpen"
        >
          <template #body="slotProps">
            <div class="bold fs-14">
              {{ paymentCurrency.symbol || '' }}{{ slotProps.data.price }}
            </div>
          </template>
        </Column>
        <Column
          field="tokens"
          header="Lookups"
          :sortable="isPaymentHistoryOpen"
        >
          <template #body="slotProps">
            <div class="flex align-center bold fs-14">
              {{ slotProps.data.tokens }}
              <gl-icon
                class="ml-1 mr-1"
                :height="12"
                name="lookups-dark"
                :width="12"
              />
            </div>
          </template>
        </Column>
        <Column
          field="createdAt"
          header="Date & Time"
          :sortable="isPaymentHistoryOpen"
        >
          <template #body="slotProps">
            <div class="bold fs-14">
              {{
                slotProps.data.createdAt
                  ? formatDate(
                    new Date(slotProps.data.createdAt),
                    'dd.MM.yyyy HH:mm',
                  )
                  : '–'
              }}
            </div>
          </template>
        </Column>
        <Column
          field="status"
          header="Status"
          :sortable="isPaymentHistoryOpen"
        >
          <template #body="slotProps">
            <div
              class="bold fs-14 capitalize"
              :style="{
                color: `${getObjectValueByKey(
                  PaymentStatusColorMap,
                  slotProps.data.status,
                )}`,
              }"
            >
              {{ slotProps.data.status }}
            </div>
          </template>
        </Column>
        <template #empty>
          No records found.
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
//Vuex
import { mapActions, mapGetters } from 'vuex'
// Components
import GlIcon from '@/components/gl-icon'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
// Utils
import { formatDate } from '@/utils/format-date'
import { PaymentStatusColorMap } from '@/utils/get-status'
import { getObjectValueByKey } from '@/utils/helpers'

export default {
  name: 'PaymentHistory',
  components: {
    GlIcon,
    DataTable,
    Column,
  },
  props: {
    isPaymentHistoryOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      pagesOptions: [5, 10, 20, 50, 100],
      perPage: 10,
      historyData: [],
      PaymentStatusColorMap,
      totalRecords: 0,
      lazyParams: {},
    }
  },
  computed: {
    ...mapGetters('user', ['paymentCurrency']),
    ...mapGetters('adminPanel', ['paymentHistoryReloadTrigger']),
  },
  watch: {
    paymentHistoryReloadTrigger() {
      this.loadData()
    }
  },
  mounted() {
    this.lazyParams = {
      pageSize: this.perPage,
      pageNumber: 1,
      sort: {},
    }
    this.loadData()
  },
  methods: {
    formatDate,
    getObjectValueByKey,
    ...mapActions({
      getPaymentHistoryList: 'payment/getPaymentHistoryList',
    }),
    async loadData() {
      this.loading = true

      await this.getPaymentHistoryList({
        lazyParams: this.lazyParams,
      })
        .then(({ success, data }) => {
          data
          if (success) {
            this.totalRecords = data.pagesTotal
            this.historyData = data.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPage(event) {
      this.lazyParams.pageSize = event.rows
      this.lazyParams.pageNumber = event.page + 1
      this.loadData()
    },
    onSort(event) {
      this.lazyParams.sort = {}
      if (event.sortField) {
        this.lazyParams.sort[event.sortField] = event.sortOrder
      }
      this.loadData()
    },
  },
}
</script>

<style>
.payment-history {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}
.payment-history-head-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-left: 0;
  font-weight: 600;
  font-size: 24px;
}
.payment-history-table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100dvh - var(--header-height) - 130px);
}

.payment-history-table-wrapper .p-datatable .p-datatable-header {
  background: unset;
  color: unset;
  border: none;
  border-width: 1px 0 1px 0;
  padding: unset;
  font-weight: unset;
}

.payment-history-table-wrapper .p-datatable .p-paginator-bottom {
  border: none;
}

.payment-history
  .p-datatable-responsive-scroll
  > .p-datatable-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (max-width: 1023px) {
  .payment-history {
    background: var(--white);
    border: 1px solid var(--light-grey-e-f);
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(1, 7, 25, 0.1);
    height: calc(var(--main-content-height) - 70px);
    margin-top: 24px;
  }
  .payment-history-head-wrapper {
    padding: 16px;
    font-size: 18px;
  }

  .payment-history-table-wrapper {
    max-height: calc(var(--main-content-height) - 130px);
  }

  .payment-history .p-paginator-bottom {
    border-radius: 0 0 8px 8px;
  }

  .payment-history .p-datatable .p-datatable-thead > tr > th,
  .payment-history .p-datatable .p-datatable-tbody > tr > td {
    padding-left: 16px;
  }
}
</style>
