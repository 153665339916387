var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"select",staticClass:"gl-select__wrapper",class:{
    'gl-select__wrapper--full': _vm.fullWidth,
    'gl-select__light-gray ': _vm.lightGray,
  },attrs:{"name":_vm.name || _vm.label,"rules":_vm.rules,"tag":"div","vid":_vm.vid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
  var required = ref.required;
  var ariaInput = ref.ariaInput;
  var ariaMsg = ref.ariaMsg;
  var reset = ref.reset;
return [(_vm.label || _vm.maxLength)?_c('div',{staticClass:"flex space-between"},[_c('label',{staticClass:"gl-select__label",class:{ 'gl-select__label--invalid': errors[0] },attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label || _vm.name)+" ")]),(_vm.rules.includes('max') && _vm.maxLength)?_c('div',{staticClass:"gl-select__label gl-modal__title--capitalize"},[_vm._v(" "+_vm._s(_vm.value.length)+" / "+_vm._s(_vm.maxLength)+" Symbols ")]):_vm._e()]):_vm._e(),_c('v-select',{style:(("min-height: " + _vm.minHeight + _vm.units + "; margin-bottom: " + _vm.extraMarginBottom + "; width: " + _vm.width + ";")),attrs:{"clearable":_vm.clearable,"disabled":_vm.disabled,"label":_vm.labelSelect,"min":_vm.min,"multiple":_vm.multiple,"options":_vm.options,"placeholder":_vm.placeholder,"searchable":_vm.searchable},on:{"open":_vm.open},scopedSlots:_vm._u([{key:"selected-option",fn:function(selected){return [_vm._t("selected-slot",null,{"data":selected})]}},{key:"option",fn:function(option){return [_vm._t("option-slot",null,{"data":option})]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}),(errors[0])?_c('span',_vm._b({staticClass:"gl-select__error",class:{ 'gl-select__error--block': _vm.isBlockErrorStyle }},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }