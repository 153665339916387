<template>
  <div class="cart-buy-content-wrapper">
    <div class="bold-700 mb-3 fs-20 m-fs-18">
      {{ cartData.name }}
    </div>
    <div class="bold-700 ff-exo-2 fs-34 m-fs-32 mb-3">
      <span
        v-if="showPrice"
        class="bluet-text"
      >{{ paymentCurrency.symbol || '' }}{{ cartData.price }} </span><span
        v-if="showPrice"
        class="bold m-fs-24"
      >/</span>
      <span
        class="m-fs-24 white-space-nowrap"
        :class="{
          'bluet-text': !showPrice,
        }"
      >
        {{ cartData.tokens
        }}<gl-icon
          class="ml-1 mr-1"
          :height="isMobile ? 18 : 24"
          :name="showPrice ? 'lookups-dark' : 'lookups-blue'"
          :width="isMobile ? 18 : 24"
        />
      </span>
    </div>
    <div
      v-for="(tag, i) in cartData.labels"
      :key="tag.label"
      class="payment-box-tag-wrapper flex"
    >
      <div class="flex align-center">
        <gl-icon
          v-if="!i"
          class="mr-2"
          dark
          :height="16"
          name="done"
          :width="19"
        />
        <gl-icon
          v-else
          class="mr-2"
          :height="16"
          name="discount"
          :width="19"
        />
      </div>
      <div
        class="fs-14 bold landing-page-pricing-card-text-find-ico"
        v-html="tag.label"
      />
    </div>
  </div>
</template>

<script>
//Components
import GlIcon from '@/components/gl-icon'
// Vuex
import { mapGetters } from 'vuex'
//Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlIcon,
  },
  mixins: [deviceWidthMixin],
  props: {
    cartData: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['paymentCurrency']),
  },
}
</script>

<style>
.cart-buy-content-wrapper {
  margin-bottom: 16px;
}
.payment-box-tag-wrapper {
  margin-bottom: 16px;
}
.payment-box-tag-wrapper:last-child {
  margin-bottom: 0;
}
.landing-page-pricing-card-text-find-ico span {
  margin-left: 4px;
  padding-left: 20px;
  position: relative;
}
.landing-page-pricing-card-text-find-ico span:before {
  content: '';
  display: block;
  width: 16px;
  height: 17px;
  background: url('~@/assets/images/lookup-icon.png');
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
@media (max-width: 767px) {
  .cart-buy-content-wrapper {
    margin-bottom: 0;
  }
}
</style>
