<template>
  <div class="redeem-promo">
    <RedeemPromoImage class="redeem-promo__background" />
    <div class="redeem-promo__content">
      <div class="redeem-promo__content-top">
        <div class="redeem-promo__header">
          Promo-code
        </div>
        <div class="redeem-promo__subheader">
          Get free lookups
        </div>
        <input
          v-model="redeemPromo"
          class="redeem-promo__input"
          placeholder="Enter promo-code here"
          type="text"
        >
      </div>
      <div>
        <gl-button
          class="redeem-promo__button"
          dark
          :disabled="!disabledRedeemButton"
          full
          :loading="redeemPromoLoadingState"
          title="Redeem Lookups"
          @click="sendPromo"
        />
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions, mapGetters } from 'vuex'

// Components
import GlButton from '@/components/gl-button.vue'
import RedeemPromoImage from './svg/RedeemPromoImage.vue'

export default {
  components: {
    GlButton,
    RedeemPromoImage,
  },
  data() {
    return {
      redeemPromo: '',
    }
  },
  computed: {
    ...mapGetters('adminPanel', ['redeemPromoLoadingState']),
    disabledRedeemButton() {
      return this.redeemPromo.trim().length > 0
    }
  },
  methods: {
    ...mapActions('adminPanel', ['redeemCode']),
    sendPromo() {
      this.disabledRedeemButton && this.redeemCode(this.redeemPromo.toUpperCase())
    }
  }
}
</script>

<style scoped>
.redeem-promo {
  background-color: var(--reflex-bluet);
  border: 1px solid var(--reflex-bluet);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.redeem-promo__background {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  transform: translate(27px, -25px) rotate(-18deg);
}
.redeem-promo:hover .redeem-promo__background {
  animation: animateEye 3s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes animateEye {
  0% {
    transform: translate(27px, -25px) rotate(-18deg);
  }
  20% {
    transform: translate(21px, -20px) rotate(16deg);
  }
  40% {
    transform: translate(15px, -16px) rotate(-14deg);
  }
  60% {
    transform: translate(12px, -10px) rotate(12deg);
  }
  80% {
    transform: translate(5px, -7px) rotate(-10deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
.redeem-promo__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
  padding: 24px;
  position: relative;
  z-index: 5;
}
.redeem-promo__header {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  color: var(--white);
}
.redeem-promo__subheader {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 14px;
  color: var(--white);
}
.redeem-promo__input {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  background-color: var(--dark-blue);
  border: none;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  line-height: 1;
  color: var(--white);
  text-transform: uppercase;
}
.redeem-promo__input::placeholder {
  color: rgba(255,255,255,0.4);
  text-transform: none;
}
.redeem-promo__button {
  width: 100%;
  height: 34px;
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 4px;
  font-family: 'Exo 2', 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.6px;
  color: var(--reflex-bluet);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.redeem-promo__button:disabled {
  cursor: default;
}
.redeem-promo__button:hover:not(:disabled) {
  background-color: var(--pale-blue);
}

@media (max-width: 767px) {
  .redeem-promo__content {
    padding: 16px;
  }
}
</style>