<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    icon="all-done"
    :icon-height="32"
    :icon-width="32"
    ok-only
    submit-title="Got it"
    title="All done!"
    width="480"
    v-on="$listeners"
    @submit="confirm"
  >
    <template #mainSlot>
      <div class="success-modal-message-wrapper break-word">
        {{ message }}
      </div>
    </template>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style>
.success-modal-message-wrapper {
  padding: 24px 13px 24px 24px;
  font-weight: 500;
  line-height: 150%;
}
</style>
