<template>
  <GlPageWrap>
    <div class="payment-wrapper">
      <div class="payment">
        <div class="payment-info bold-600">
          <div class="fs-36 m-fs-24">
            Balance
          </div>
          <div class="payment-info-block bold">
            <span class="bluet-text fs-36 mr-2 bold-700 ff-exo-2">{{
              userData.balance || 0
            }}</span>
            <gl-icon
              :height="isMobile ? 22 : 24"
              name="lookups-blue"
              :width="isMobile ? 22 : 24"
            />
          </div>
        </div>
        <div
          v-if="isMobile"
          class="flex justify-center text-center fs-14 bold mb-3"
        >
          Use coins to buy reports. Choose the bundle that suits you best
        </div>

        <div
          v-if="loadingPackages"
          class="payment-empty-page"
        >
          <gl-loader width="64px" />
        </div>

        <div
          v-else
          class="payment-box-wrapper"
        >
          <CartBuy
            v-for="(cart, i) in cartsResult"
            :key="i"
            :cart-data="cart"
            @button-click="boxButtonClick"
          >
            <CartBuyContent
              :cart-data="cart"
              :show-price="isMobile"
            />
          </CartBuy>
          <RedeemPromo />
        </div>
        <div
          class="payment-history-wrapper"
          :class="{ 'payment-history-open': isPaymentHistoryOpen }"
        >
          <PaymentHistory
            :is-payment-history-open="isPaymentHistoryOpen"
            @toggle-history="SET_IS_PAYMENT_HISTORY_OPEN(!isPaymentHistoryOpen)"
          />
        </div>
      </div>

      <BuyPackageModal
        v-model="buyPackageModal"
        :loading="buyPackageModalLoading"
        :package-data="packageData"
        @close="buyPackageModal = false"
        @confirm="confirmModalPayment"
      />
      <SuccessModal
        v-model="successModal"
        :loading="buyPackageModalLoading"
        :payment-status="paymentStatus"
        @confirm="confirmModalSuccess"
      />
      <ProceedModal
        v-model="isProceedModalOpen"
        message="Continue from the previous point?"
        @close="closeProceedModal"
        @confirm="confirmProceedModal"
      />
      <SuccessRedeemModal
        v-model="redeemModalState"
        :message="`You have received ${amountOfTokens} Lookups!`"
        @confirm="redeemModalState = false"
      />
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

//Components
import GlIcon from '@/components/gl-icon'
import GlPageWrap from '@/components/layout/gl-page-wrap'
import CartBuy from './components/CartBuy.vue'
import PaymentHistory from '@/pages/payment/components/PaymentHistory'
import BuyPackageModal from '@/pages/payment/modals/BuyPackageModal'
import SuccessModal from '@/pages/payment/modals/SuccessModal'
import SuccessRedeemModal from '@/pages/payment/modals/SuccessRedeemModal'
import ProceedModal from '@/pages/payment/modals/ProceedModal'
import CartBuyContent from '@/pages/payment/components/CartBuyContent'
import GlLoader from '@/components/gl-loader'
import RedeemPromo from './components/RedeemPromo.vue'

//Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

// Libs
import _ from 'lodash'

export default {
  name: 'SourceOfFunds',
  components: {
    GlIcon,
    GlPageWrap,
    GlLoader,
    CartBuy,
    PaymentHistory,
    BuyPackageModal,
    CartBuyContent,
    SuccessModal,
    ProceedModal,
    RedeemPromo,
    SuccessRedeemModal,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      isProceedModalOpen: false,
      notEnoughTokenPath: '',
      buyPackageModalLoading: false,
      loadingPackages: false,
      buyPackageModal: false,
      successModal: false,
      paymentStatus: '',
      packageData: {},
      carts: [],
      redeemModalState: false,
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('sidebar', ['isPaymentHistoryOpen']),
    ...mapGetters('adminPanel', ['confirmModalRedeemTrigger', 'amountOfTokens']),
    cartsResult() {
      return this.carts.slice().sort((a, b) => a.price - b.price)
    },
  },
  watch: {
    confirmModalRedeemTrigger(newVal) {
      this.redeemModalState = newVal
    }
  },
  created() {
    this.loadPackages()
    const { query } = this.$route

    if (query.success == 'true') {
      this.paymentStatus = "success"
      this.successModal = true
    } else if (query.success == 'false') {
      this.paymentStatus = "failed"
      this.successModal = true
    }
  },
  methods: {
    ...mapActions('payment', [
      'getPackages',
      'buyPackage',
      'setCustomPackage',
      'buyCustomPackage',
    ]),
    ...mapActions('user', ['getMe']),
    ...mapMutations({
      SET_IS_PAYMENT_HISTORY_OPEN: 'sidebar/SET_IS_PAYMENT_HISTORY_OPEN',
    }),
    async boxButtonClick(data) {
      if (!data.price || !data.tokens) return
      this.packageData = data
      this.buyPackageModal = true
    },
    async customBoxButtonClick(data) {
      if (!data.price || !data.tokens) return
      this.packageData = data
      this.buyPackageModal = true
    },

    priceValueChangeDebounce: _.debounce((value, vm) => {
      vm.setCustomPackageByValue({ price: value })
    }, 500),
    priceValueChange(value) {
      this.priceValueChangeDebounce(value, this)
    },
    tokensValueChangeDebounce: _.debounce((value, vm) => {
      vm.setCustomPackageByValue({ tokens: value })
    }, 500),
    tokensValueChange(value) {
      this.tokensValueChangeDebounce(value, this)
    },
    confirmModalPayment({ packageData, paymentMethod }) {
      if (!packageData.price || !packageData.tokens) return

      this.buyPackageModalLoading = true

      if (packageData.key === 'custom') {
        this.buyCustomPackage({
          price: packageData.price,
          // tokens: packageData.tokens,
          paymentMethod: paymentMethod.key,
        })
          .then(({ success, data: { redirectUrl } }) => {
            if (success) {
              window.location.replace(redirectUrl)
            }
          })
          .finally(() => {
            this.buyPackageModalLoading = false
            this.buyPackageModal = false
          })
      } else {
        this.buyPackage({
          packageId: packageData._id,
          paymentMethod: paymentMethod.key,
        })
          .then(({ success, data: { redirectUrl } }) => {
            if (success) {
              window.location.replace(redirectUrl)
            }
          })
          .finally(() => {
            this.buyPackageModalLoading = false
            this.buyPackageModal = false
          })
      }
    },
    loadPackages() {
      this.loadingPackages = true
      this.getPackages()
        .then(({ data, success }) => {
          if (success) {
            this.carts = data
            this.checkRoutParams()
          }
        })
        .finally(() => {
          this.loadingPackages = false
        })
    },
    confirmModalSuccess() {
      this.successModal = false
      this.paymentStatus = ''
      this.$router.replace({ query: null })
      this.notEnoughTokenPath = localStorage.getItem('notEnoughTokenPath')
      if (this.notEnoughTokenPath) {
        this.isProceedModalOpen = true
      }
    },
    confirmProceedModal() {
      this.isProceedModalOpen = false
      localStorage.removeItem('notEnoughTokenPath')
      this.$router.push(this.notEnoughTokenPath)
    },
    closeProceedModal() {
      this.isProceedModalOpen = false
      localStorage.removeItem('notEnoughTokenPath')
    },
    checkRoutParams() {
      if(this.$route.params.key) {
        const cartFromPricing = this.cartsResult.find(item => item.key === this.$route.params.key)
        this.boxButtonClick(cartFromPricing)
      }
    },
  },
}
</script>

<style>
:root {
  --payment-close-height: calc(120px + var(--report-banner-height));
}
.payment-wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--light-grey-f-8);
  padding-top: var(--header-height);
  font-family: 'Montserrat', sans-serif;
  padding-bottom: var(--payment-close-height);
}

.payment-empty-page {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  min-height: calc(var(--main-content-height) - var(--payment-close-height));
}

.payment-info {
  display: flex;
  gap: 36px;
  margin-top: 24px;
  margin-bottom: 48px;
}
.payment-info-block {
  display: flex;
  align-items: baseline;
}

.payment-history-wrapper {
  height: 100%;
  padding: 0;
}

.payment-box-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 24px 30px;
  margin-bottom: 50px;
}

.payment-history-wrapper {
  position: absolute;
  top: calc(100dvh - var(--payment-close-height));
  left: 0;
  bottom: 0px;
  z-index: 20;
  background: var(--white);
  display: flex;
  justify-content: center;
  width: var(--full-width);
  box-shadow: 0px 0px 100px 0px var(--dark-transparent);
  transition: all 0.7s;
  max-height: calc(100dvh - var(--header-height));
  overflow: auto;
}
.payment-history-wrapper.payment-history-open {
  top: var(--header-height);
  transition: all 0.7s;
}

@media (max-width: 1023px) {
  .payment-wrapper {
    background: var(--grey-white);
    padding-bottom: var(--report-banner-height);
  }
  .payment-history-wrapper {
    background: var(--grey-white);
    display: none;
  }
  .payment-history-wrapper.payment-history-open {
    display: flex;
  }
  .payment-box-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 767px) {
  .payment-info {
    justify-content: center;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 16px;
  }

  .payment-box-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 80px;
  }
}
</style>
