<template>
  <GlBoxWrap class="payment-box">
    <div class="m-flex-1">
      <slot />
    </div>
    <div>
      <gl-button
        class="m-display-none"
        dark
        :disabled="!cartData.price || cartData.price == 0 || cartData.disabled"
        full
        :loading="cartData.loading"
        style="height: 34px"
        :title="buttonTitle"
        @click="$emit('button-click', cartData)"
      />
      <gl-icon
        class="display-none m-flex pointer"
        :height="40"
        name="arrow-forward"
        :width="40"
        @click="$emit('button-click', cartData)"
      />
    </div>
  </GlBoxWrap>
</template>

<script>
//Components
import GlBoxWrap from '@/components/layout/gl-box-wrap.vue'
import GlButton from '@/components/gl-button'
import GlIcon from '@/components/gl-icon'
// Vuex
import { mapGetters } from 'vuex'

export default {
  components: {
    GlBoxWrap,
    GlButton,
    GlIcon,
  },
  props: {
    cartData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('user', ['paymentCurrency']),
    buttonTitle() {
      return this.cartData.price && this.cartData.price != 0
        ? `Buy for ${this.paymentCurrency.symbol || ''}${this.cartData.price}`
        : 'Buy'
    },
  },
}
</script>

<style>
.payment-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .payment-box {
    flex-direction: row;
    align-items: end;
  }
}
</style>
